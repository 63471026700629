import { Injectable, inject } from "@angular/core";
import { FdBaseComponent } from "@fd/core";
import { UserDtoModel } from "../modules/account/models/user-dto.model";
import { UserRoles } from "../modules/shared/enums/user-roles.enum";
import { AuthService } from "../modules/shared/services/auth.service";
import { AppRoutes } from "../routes";
import { FdStateService } from "../services/fd-state.service";

import { IAppEnvironment } from "src/environments/environment.definition";
@Injectable()
export abstract class BaseComponent extends FdBaseComponent<UserDtoModel, AuthService> {
  protected readonly AppRoutes = AppRoutes;
  protected readonly UserRoles = UserRoles;
  protected readonly fdStateService = inject(FdStateService);
  override readonly environment: IAppEnvironment;
}
