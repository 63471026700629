import { AppEnvironment, ExternalRouteInfo, FdRouteInfo, FdRouteInfoData, Primitives } from "@fd/core";
import { environment } from "src/environments/environment";
import { UserRoles } from "./modules/shared/enums/user-roles.enum";

export class RouteInfo<PathParams extends Primitives[] = [], QueryParams extends {} = {}> extends FdRouteInfo<
  UserRoles,
  PathParams,
  QueryParams
> {}

export class AppRoutes {
  public static readonly root = new RouteInfo(null, "", { title: "", allowAnonymous: true });

  public static readonly login = new RouteInfo(AppRoutes.root, "login", {
    title: "Login",
    showInMenu: false,
    allowAnonymous: true,
  });
  public static readonly home = new RouteInfo(AppRoutes.root, "home", { title: "Home", showInMenu: false });
  public static readonly notAuthorized = new RouteInfo(AppRoutes.root, "not-authorized", {
    title: "Not Authorized",
    showInMenu: false,
    allowAnonymous: true,
  });

  // #region Admin Module
  public static adminRoot = new RouteInfo(AppRoutes.root, "admin", {
    title: "System",
    roleArea: UserRoles.Admin,
  });
  public static admin = {
    schools: new RouteInfo(AppRoutes.adminRoot, "schools", {
      title: "Schools",
      roleView: UserRoles.Admin_Schools_View,
      additionalRoles: [UserRoles.Admin_Schools_Edit],
    }),
    users: new RouteInfo(AppRoutes.adminRoot, "users", {
      title: "Users",
      roleView: UserRoles.Admin_Users_View,
      additionalRoles: [UserRoles.Admin_Users_Edit, UserRoles.Admin_ImpersonateUser, UserRoles.Admin_CanAssignGroups],
    }),
    securityGroups: new RouteInfo(AppRoutes.adminRoot, "security-groups", {
      title: "Security Groups",
      roleView: UserRoles.Admin_SecurityGroups_View,
      additionalRoles: [UserRoles.Admin_SecurityGroups_Edit],
    }),
    objectives: new RouteInfo(AppRoutes.adminRoot, "objectives", {
      title: "Objectives",
      roleView: UserRoles.Admin_Objectives_View,
      additionalRoles: [UserRoles.Admin_Objectives_Edit],
    }),
    contentAreas: new RouteInfo(AppRoutes.adminRoot, "content-areas", {
      title: "Content Areas",
      roleView: UserRoles.Admin_ContentAreas_View,
      additionalRoles: [UserRoles.Admin_ContentAreas_Edit],
    }),
  };
  // #endregion

  // #region School Module
  public static schoolRoot = new RouteInfo(AppRoutes.root, "school", {
    title: "School",
    roleArea: UserRoles.School,
  });
  public static school = {
    schoolSettings: new RouteInfo<["schoolyear" | "schoolbreak"]>(
      AppRoutes.schoolRoot,
      "school-setting/:?settingType",
      {
        title: "Settings",
        defaultPathParams: ["schoolyear"],
        roleView: UserRoles.School_SchoolYears_View,
        additionalRoles: [UserRoles.School_SchoolYears_Edit],
      }
    ),

    classes: new RouteInfo(AppRoutes.schoolRoot, "classes", {
      title: "Classes",
      roleView: UserRoles.School_Classes_View,
      additionalRoles: [UserRoles.School_Classes_Edit],
    }),
    staff: new RouteInfo(AppRoutes.schoolRoot, "staff", {
      title: "Staff",
      roleView: UserRoles.School_Staff_View,
      additionalRoles: [UserRoles.School_Staff_Edit, UserRoles.Admin_CanAssignGroups],
    }),
    students: new RouteInfo(AppRoutes.schoolRoot, "students", {
      title: "Students",
      roleView: UserRoles.Student_Students_View,
      additionalRoles: [UserRoles.Student_Students_Edit],
    }),
    objectives: new RouteInfo(AppRoutes.schoolRoot, "objectives", {
      title: "Objectives",
      roleView: UserRoles.School_SchoolObjectives_View,
      additionalRoles: [UserRoles.School_SchoolObjectives_Edit],
    }),
  };
  // #endregion

  // #region Teaching Module
  public static teachingRoot = new RouteInfo(AppRoutes.root, "teaching", {
    title: "Teaching",
    roleArea: UserRoles.Teaching,
  });
  public static teaching = {
    weeklyPlans: new RouteInfo(AppRoutes.teachingRoot, "weekly-planning", {
      title: "Weekly Planning",
      roleView: UserRoles.Teaching_WeeklyPlans_View,
      additionalRoles: [UserRoles.Teaching_WeeklyPlans_Edit],
    }),
    grading: new RouteInfo<["class" | "student"]>(AppRoutes.teachingRoot, "grading/:?gradeType", {
      title: "Grading",
      defaultPathParams: ["class"],
      roleView: UserRoles.Teaching_Grades_View,
      additionalRoles: [UserRoles.Teaching_Grades_Edit],
    }),
  };
  // #endregion

  // #region Reports Module
  public static reportsRoot = new RouteInfo(AppRoutes.root, "reports", {
    title: "Reports",
    roleArea: UserRoles.Reports,
  });
  public static reports = {
    school: new RouteInfo(AppRoutes.reportsRoot, "school", {
      title: "School Report",
      roleView: UserRoles.Reports_School_View,
      additionalRoles: [UserRoles.Reports_School_Edit],
    }),
    class: new RouteInfo(AppRoutes.reportsRoot, "class", {
      title: "Class Report",
      roleView: UserRoles.Reports_Class_View,
      additionalRoles: [UserRoles.Reports_Class_Edit],
    }),
    studentReportCard: new RouteInfo(AppRoutes.reportsRoot, "student-report-card", {
      title: "Student Report Card",
      roleView: UserRoles.Reports_StudentReportCard_View,
      additionalRoles: [UserRoles.Reports_StudentReportCard_Edit],
    }),
  };
  // #endregion

  // #region Account Module
  public static accountRoot = new RouteInfo(null, "account", {
    title: "Account",
    showInMenu: false,
    allowAnonymous: true,
  });
  public static account = {
    registration: new RouteInfo(AppRoutes.accountRoot, "registration", {
      title: "Registration",
      allowAnonymous: true,
    }),
    externalLoginCallback: new RouteInfo(AppRoutes.accountRoot, "external-login-callback", {
      title: "Logging you in...",
      allowAnonymous: true,
    }),
    confirmEmail: new RouteInfo(AppRoutes.accountRoot, "confirm-email", {
      title: "Confirm Email",
      allowAnonymous: true,
    }),
    forgotPassword: new RouteInfo(AppRoutes.accountRoot, "forgot-password", {
      title: "Forgot Password",
      allowAnonymous: true,
    }),
    resetPassword: new RouteInfo(AppRoutes.accountRoot, "reset-password", {
      title: "Reset Password",
      allowAnonymous: true,
    }),
    profile: new RouteInfo(AppRoutes.accountRoot, "profile", { title: "Profile" }),
  };
  // #endregion

  // #region Framework Module
  public static frameworkRoot = new RouteInfo(AppRoutes.root, "framework", {
    title: "Framework",
    showInMenu: environment.configuration !== AppEnvironment.Production,
  });
  public static frameworkSwaggerRoot = new ExternalRouteInfo(AppRoutes.frameworkRoot, "Swagger", "/swagger", {
    showInMenu: environment.configuration === AppEnvironment.Development,
    target: "swagger",
  });
  public static frameworkRinRoot = new ExternalRouteInfo(
    AppRoutes.frameworkRoot,
    "Request Inspector (Rin)",
    `${environment.localServerUrl}/rin`,
    {
      showInMenu: environment.configuration === AppEnvironment.Development,
      target: "rin",
    }
  );
  public static frameworkCoreRoot = new RouteInfo(AppRoutes.frameworkRoot, "core", {
    title: "Core",
    isNestedRoot: true,
  });
  public static frameworkControlsRoot = new RouteInfo(AppRoutes.frameworkRoot, "controls", {
    title: "Form Controls",
    isNestedRoot: true,
  });
  public static frameworkWidgetsRoot = new RouteInfo(AppRoutes.frameworkRoot, "widgets", {
    title: "Widgets",
    isNestedRoot: true,
  });
  public static frameworkLayoutsRoot = new RouteInfo(AppRoutes.frameworkRoot, "layouts", {
    title: "Layouts",
    isNestedRoot: true,
  });
  public static framework = {
    // core
    about: new RouteInfo(AppRoutes.frameworkCoreRoot, "about", { title: "About" }),
    cache: new RouteInfo<["server" | "client"]>(AppRoutes.frameworkCoreRoot, "cache/:?view", {
      title: "Cache",
      defaultPathParams: ["server"],
    }),

    dateTime: new RouteInfo(AppRoutes.frameworkCoreRoot, "date-time", { title: "Date & Time" }),
    email: new RouteInfo(AppRoutes.frameworkCoreRoot, "email", { title: "Email" }),
    errorHandling: new RouteInfo(AppRoutes.frameworkCoreRoot, "error-handling", { title: "Error Handling" }),
    files: new RouteInfo<["ico" | "csv" | "xlsx" | "pdf"]>(AppRoutes.frameworkCoreRoot, "download-file/:?fileType", {
      title: "Files",
      defaultPathParams: ["ico"],
    }),
    form: new RouteInfo(AppRoutes.frameworkCoreRoot, "form", { title: "Form" }),
    globalLoadingIndicator: new RouteInfo(AppRoutes.frameworkCoreRoot, "global-loading-indicator", {
      title: "Global Loading Indicator",
    }),
    invalidModelState: new RouteInfo(AppRoutes.frameworkCoreRoot, "invalid-model-state", {
      title: "Invalid Model State ",
    }),
    pipes: new RouteInfo(AppRoutes.frameworkCoreRoot, "pipes", { title: "Pipes" }),
    prototyping: new RouteInfo(AppRoutes.frameworkCoreRoot, "prototyping", { title: "Prototyping" }),
    routing: new RouteInfo<[number, string, boolean?], { age?: number; grade?: string }>(
      AppRoutes.frameworkCoreRoot,
      "routing/:id/:name/:?flag",
      {
        title: "Routing",
        defaultPathParams: [0, "_"],
        defaultQueryParams: { age: 0 },
      }
    ),

    // form controls
    autocomplete: new RouteInfo(AppRoutes.frameworkControlsRoot, "autocomplete", { title: "Autocomplete" }),
    checkbox: new RouteInfo(AppRoutes.frameworkControlsRoot, "checkbox", { title: "Checkbox" }),
    datepicker: new RouteInfo(AppRoutes.frameworkControlsRoot, "datepicker", { title: "Date / Time Picker" }),
    number: new RouteInfo(AppRoutes.frameworkControlsRoot, "number", { title: "Number" }),
    select: new RouteInfo(AppRoutes.frameworkControlsRoot, "select", { title: "Select" }),
    text: new RouteInfo(AppRoutes.frameworkControlsRoot, "text", { title: "Text" }),
    toggle: new RouteInfo(AppRoutes.frameworkControlsRoot, "toggle", { title: "Toggle" }),
    uploadFile: new RouteInfo(AppRoutes.frameworkControlsRoot, "upload-file", { title: "Upload File" }),

    // widgets
    button: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "button", { title: "Button" }),
    chart: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "chart", { title: "Chart" }),
    confirmationPopover: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "confirmation-popover", {
      title: "Confirmation Popover",
    }),
    dataTables: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "data-tables", { title: "Data Tables" }),
    dialog: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "dialog", { title: "Dialog" }),
    menu: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "menu", { title: "Menu" }),
    snackBar: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "snack-bar", { title: "Snack Bar" }),
    tooltip: new RouteInfo(AppRoutes.frameworkWidgetsRoot, "tooltip", { title: "Tooltip" }),

    // layouts
    layoutCards: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "cards/:?view", {
      title: "Cards",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutTableEditable: new RouteInfo(AppRoutes.frameworkLayoutsRoot, "table-editable", {
      title: "Editable Table",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutReport: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "report/:?view", {
      title: "Report",
      defaultQueryParams: { type: "sidebar", showViewLinks: true, showActions: false },
    }),
    layoutTable: new RouteInfo<[string]>(AppRoutes.frameworkLayoutsRoot, "table", {
      title: "Table",
      defaultQueryParams: { type: "sidebar" },
    }),
    layoutUtilities: new RouteInfo<[string?]>(AppRoutes.frameworkLayoutsRoot, "layout-utilities/:?view", {
      title: "Utilities",
    }),
  };
  // #endregion

  // #endregion
}

export type RouteInfoData<PathParams extends Primitives[] = [], QueryParams extends {} = {}> = FdRouteInfoData<
  UserRoles,
  PathParams,
  QueryParams
>;

export type PathParamsForRoute<T> = T extends RouteInfo<infer PathParams> ? PathParams : never;

export type QueryParamsForRoute<T> = T extends RouteInfo<never, infer QueryParams> ? QueryParams : never;
