import { Injectable } from "@angular/core";
import { ControllerBase } from "@fd/core";
import { Observable } from "rxjs";
import { ReportGraphItemInterface } from "../../reports/models/report-graph-item.model";
import { GradeLevel } from "../../shared/enums/grade-level.enum";
import { SchoolInterface } from "../models/school.model";

@Injectable({ providedIn: "root" })
export class SchoolApiService extends ControllerBase<SchoolInterface> {
  constructor() {
    super("School/School");
  }

  //#region super implementation
  get(includeInactive: boolean = false): Observable<SchoolInterface[]> {
    return super._get({ query: { includeInactive } });
  }

  getById(id: number) {
    return super.$getById(id);
  }

  getBySearch(searchTerm: string) {
    return super.$getBySearch(searchTerm);
  }

  post(model: SchoolInterface) {
    return super.$post(model);
  }

  put(model: SchoolInterface) {
    return super.$put(model);
  }

  delete(id: number) {
    return super.$delete(id);
  }

  count(name: string = "", excludeId?: number) {
    return super._count({ query: { name, excludeId } });
  }

  getSchoolReport(
    schoolYearId: number,
    classId: number,
    gradeLevel: GradeLevel,
    startDate: Date,
    endDate: Date
  ): Observable<ReportGraphItemInterface[]> {
    return super._get({
      url: this.getSchoolReport.name,
      query: { schoolYearId, classId, gradeLevel, startDate, endDate },
    });
  }
  //#endregion
}
