import { DestroyRef, EventEmitter, Injectable, Renderer2, RendererFactory2, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { LocalStoreManager } from "@fd/core";
import { filter, fromEvent, map } from "rxjs";
import { DbKeys } from "../modules/shared/enums/db-keys.enum";
import { AuthService } from "../modules/shared/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class FdStateService {
  private renderer: Renderer2;

  readonly defaults = {
    timeFormat24hour: true,
  };
  isDrawerOpen: boolean;
  schoolId: number;
  onSchoolId = new EventEmitter<number>();
  onNewSchoolAdded = new EventEmitter<{ id: number }>();
  onShowOutliers = new EventEmitter<boolean>();
  timeFormat24hour: boolean = this.defaults.timeFormat24hour;

  showSchools: boolean = true;

  onLightModeChange = new EventEmitter<boolean>();
  get isLightMode(): boolean {
    return this.localStoreManager.getDataObject<boolean>(DbKeys.LIGHT_MODE) || false;
  }
  set isLightMode(value: boolean) {
    this.localStoreManager.savePermanentData(value, DbKeys.LIGHT_MODE);
    this.toggleLightMode();
  }

  constructor(
    authService: AuthService,
    rendererFactory: RendererFactory2,
    private localStoreManager: LocalStoreManager
  ) {
    this.renderer = rendererFactory.createRenderer(document.body, null);
    this.toggleLightMode();
    this.schoolId = authService.currentUser?.schoolId ?? this.localStoreManager.getDataObject<number>(DbKeys.SCHOOL_ID);

    fromEvent<StorageEvent>(window, "storage")
      .pipe(
        takeUntilDestroyed(inject(DestroyRef)),
        filter(event => event.key === DbKeys.SCHOOL_ID),
        map(event => Number(event.newValue) || this.schoolId),
        filter(newSchoolId => newSchoolId !== this.schoolId)
      )
      .subscribe(newSchoolId => {
        this.schoolId = newSchoolId;
        this.onSchoolId.emit(this.schoolId);
      });
  }

  schoolIdChanged() {
    this.localStoreManager.savePermanentData(this.schoolId, DbKeys.SCHOOL_ID);
    this.onSchoolId.emit(this.schoolId);
  }

  newSchoolAdded() {
    this.onNewSchoolAdded.emit();
  }

  public toggleLightMode() {
    const darkModeCssClass = "theme-alternate";

    if (this.isLightMode) {
      this.renderer.removeClass(document.body, darkModeCssClass);
    } else {
      this.renderer.addClass(document.body, darkModeCssClass);
    }

    this.onLightModeChange.emit(this.isLightMode);
  }
}
