import { Injectable, inject } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Observable, Subject, Subscriber, of, throwError } from "rxjs";
import { catchError, finalize, map, mergeMap, switchMap, tap } from "rxjs/operators";

import { AbstractAuthService, LocalStoreManager, Utilities } from "@fd/core";
import { DbKeys } from "src/app/modules/shared/enums/db-keys.enum";
import { UserRoles } from "src/app/modules/shared/enums/user-roles.enum";
import { AppRoutes, RouteInfoData } from "src/app/routes";
import { UserDtoInterface, UserDtoModel } from "../../account/models/user-dto.model";
import { AccountApiService } from "../../account/services/account-api.service";

import { environment as appEnvironment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class AuthService extends AbstractAuthService<UserDtoInterface> {
  private readonly router = inject(Router);
  private readonly localStorage = inject(LocalStoreManager);
  private readonly accountApiService = inject(AccountApiService);

  private readonly refreshing: Subscriber<UserDtoInterface>[] = [];

  private previousIsLoggedInCheck = false;
  private loginStatus = new Subject<boolean>();

  loginRedirectUrl: string;
  logoutRedirectUrl: string;

  get environment() {
    return appEnvironment;
  }

  get currentUser(): UserDtoInterface {
    const user = this.localStorage.getDataObject<UserDtoInterface>(DbKeys.CurrentUser);
    this.reevaluateLoginStatus(user);

    return user ? Object.assign(new UserDtoModel(), user) : null;
  }

  get isLoggedIn(): boolean {
    return this.currentUser != null;
  }

  get rememberMe(): boolean {
    return this.localStorage.getDataObject<boolean>(DbKeys.RememberMe) === true;
  }

  set rememberMe(value: boolean) {
    this.localStorage.savePermanentData(value, DbKeys.RememberMe);
  }

  get loginUrl() {
    return AppRoutes.login.routerLink;
  }

  get confirmEmailUrl() {
    return AppRoutes.account.confirmEmail.routerLink;
  }

  get notAuthorizedUrl() {
    return AppRoutes.notAuthorized.routerLink;
  }

  constructor() {
    super();
    this.initializeLoginStatus();
  }

  getReturnUrl(): {
    firstPart: string;
    extras: NavigationExtras;
    wholePart: string;
  } {
    const redirect =
      this.loginRedirectUrl && this.loginRedirectUrl !== "/" && this.loginRedirectUrl !== "/"
        ? this.loginRedirectUrl
        : "/";
    this.loginRedirectUrl = null;

    const urlParamsAndFragment = Utilities.splitInTwo(redirect, "#");
    const urlAndParams = Utilities.splitInTwo(urlParamsAndFragment.firstPart, "?");

    const navigationExtras: NavigationExtras = {
      fragment: urlParamsAndFragment.secondPart,
      queryParams: Utilities.getQueryParamsFromString(urlAndParams.secondPart),
      queryParamsHandling: "merge",
    };

    return {
      firstPart: urlParamsAndFragment.firstPart,
      extras: navigationExtras,
      wholePart: redirect,
    };
  }

  redirectLoginUser() {
    const returnUrl: string = this.localStorage.getData(DbKeys.ReturnUrl);
    if (returnUrl) {
      this.localStorage.deleteData(DbKeys.ReturnUrl);
      this.router.navigateByUrl(returnUrl);
    } else {
      const { firstPart, extras } = this.getReturnUrl();
      this.router.navigate([firstPart], extras);
    }
  }

  redirectLogoutUser() {
    const redirect = this.logoutRedirectUrl ? this.logoutRedirectUrl : this.loginUrl;
    this.logoutRedirectUrl = null;

    this.router.navigate([redirect]);
  }

  redirectForLogin() {
    this.loginRedirectUrl = this.router.url;
    return this.router.navigateByUrl(this.loginUrl);
  }

  signIn(userName: string, password: string, rememberMe?: boolean, backdoor?: boolean): Observable<UserDtoInterface> {
    let signIn = this.accountApiService
      .signIn(userName, password, rememberMe, backdoor)
      .pipe(map((response: UserDtoInterface) => this.processLoginResponse(response, rememberMe)));

    if (this.isLoggedIn) {
      signIn = this.signOut().pipe(switchMap(() => signIn));
    }

    return signIn;
  }

  confirmEmail(emailAddress: string, code: string): Observable<UserDtoInterface> {
    return this.accountApiService
      .confirmEmail(emailAddress, code)
      .pipe(map((response: UserDtoInterface) => this.processLoginResponse(response)));
  }

  refreshUser(): Observable<UserDtoInterface> {
    const service = this;

    return new Observable(subscriber => {
      service.refreshing.push(subscriber);

      if (service.refreshing.length <= 1) {
        const subscription = service.accountApiService
          .me()
          .pipe(
            map((response: UserDtoInterface) => {
              if (response) {
                service.processLoginResponse(response);
              }
              return response;
            }),
            tap(value => {
              while (service.refreshing.length) {
                const sub = service.refreshing.pop();
                sub.next(value);
                sub.complete();
              }
            }),
            catchError(err => {
              while (service.refreshing.length) {
                const sub = service.refreshing.pop();
                sub.error(err);
              }

              if (err && typeof err === "object" && "error" in err) {
                return this.signOut().pipe(switchMap(() => throwError(err.error)));
              }
              return of(null);
            }),
            finalize(() => {
              while (service.refreshing.length) {
                service.refreshing.pop().complete();
              }
            })
          )
          .subscribe();

        return () => subscription.unsubscribe();
      }

      return () => {};
    });
  }

  impersonateRole(reset: boolean, role?: UserRoles) {
    return this.processImpersonationRequest(this.accountApiService.impersonateRole(reset, role));
  }

  impersonateUser(id: string) {
    return this.processImpersonationRequest(this.accountApiService.impersonate(id));
  }

  abortImpersonate() {
    return this.processImpersonationRequest(this.accountApiService.abortImpersonate());
  }

  signOut(): Observable<any> {
    while (this.refreshing.length) {
      const sub = this.refreshing.pop();
      sub.complete();
    }

    this.clearUser();
    return this.accountApiService.signOut();
  }

  getLoginStatusEvent(): Observable<boolean> {
    return this.loginStatus.asObservable();
  }

  private processImpersonationRequest(request$: Observable<UserDtoInterface>) {
    return request$.pipe(
      mergeMap(user => {
        let route = this.router.routerState.root;

        while (route.firstChild) {
          route = route.firstChild;
        }

        return route.data.pipe(
          map(data => ({
            data,
            user,
          }))
        );
      }),
      map(response => {
        if (response.user) {
          this.processLoginResponse(response.user, undefined, response.data);
          this.loginStatus.next(true);
        }
        return response;
      })
    );
  }

  private processLoginResponse(user: UserDtoInterface, rememberMe?: boolean, data?: RouteInfoData) {
    rememberMe ??= this.rememberMe;

    this.saveUserDetails(user, rememberMe);
    this.reevaluateLoginStatus(user);

    if (data?.roleView && !this.currentUser.isInRole(data.roleView)) {
      this.router.navigateByUrl(AppRoutes.notAuthorized.routerLink);
    }

    return user;
  }

  private saveUserDetails(user: UserDtoInterface, rememberMe: boolean) {
    this.localStorage.savePermanentData(rememberMe, DbKeys.RememberMe);
    this.localStorage.saveData(user, DbKeys.CurrentUser);
  }

  private clearUser() {
    this.localStorage.deleteData(DbKeys.UserPermissions);
    this.localStorage.deleteData(DbKeys.CurrentUser);
    this.reevaluateLoginStatus();
  }

  private reevaluateLoginStatus(currentUser?: UserDtoInterface) {
    const user = currentUser || this.localStorage.getDataObject<UserDtoInterface>(DbKeys.CurrentUser);
    const isLoggedIn = user != null;

    if (this.previousIsLoggedInCheck !== isLoggedIn) {
      setTimeout(() => {
        this.loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;
  }

  private initializeLoginStatus() {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
    });
  }
}
