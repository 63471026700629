import { Component, Input, OnInit, inject } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";
import { AppEnvironment, LocalStoreManager } from "@fd/core";
import { CookieService } from "ngx-cookie-service";
import { Observable, Subscription, of } from "rxjs";
import { catchError, debounceTime, filter, map, mergeMap, shareReplay, tap } from "rxjs/operators";
import { AccountHubService } from "src/app/modules/account/services/account-hub.service";
import { SchoolInterface } from "src/app/modules/school/models/school.model";
import { SchoolApiService } from "src/app/modules/school/services/school-api.service";
import { DbKeys } from "src/app/modules/shared/enums/db-keys.enum";
import { UserRoles } from "src/app/modules/shared/enums/user-roles.enum";
import { AppRoutes, RouteInfoData } from "src/app/routes";
import { environment } from "src/environments/environment";
import { BaseComponent } from "../base.component";

@Component({
  selector: "app-toolbar",
  templateUrl: "./app-toolbar.component.html",
  styleUrl: "./app-toolbar.component.scss",
})
export class AppToolbarComponent extends BaseComponent implements OnInit {
  @Input() drawer: MatSidenav;
  @Input() appTitle: string;
  private readonly accountHubService = inject(AccountHubService);
  private readonly schoolApiService = inject(SchoolApiService);
  private readonly cookieService = inject(CookieService);
  private readonly localStorage = inject(LocalStoreManager);
  private readonly router = inject(Router);
  readonly AppEnvironment = AppEnvironment;
  $schools: Observable<SchoolInterface[]>;
  clientEnvironment = environment.configuration;
  refreshAccountSubscription: Subscription;
  schoolName: string;

  /** Stores all the roles associated with the current route for easy testing. Only applicable in non-production environments. */
  applicableRoles: UserRoles[] = [];

  get fullName(): string {
    return this.authService.currentUser
      ? `${this.authService.currentUser.firstName} ${this.authService.currentUser.lastName}`
      : "";
  }

  get isImpersonatingUser(): boolean {
    return this.authService.currentUser?.impersonatingIdentityId ? true : false;
  }

  get serverEnvironment() {
    return AppEnvironment[this.cookieService.get("Environment") as keyof typeof AppEnvironment]; // the server responds with the initial page load with an Environment cookie
  }

  get showLogin(): boolean {
    return this.router.url !== AppRoutes.login.routerLink && !this.authService.isLoggedIn;
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.authService
        .refreshUser()
        .pipe(
          catchError(err => {
            if (err.status === 401) {
              this.signOut();
            }

            return of(false);
          })
        )
        .subscribe();
    }

    this.finalizeInit();

    if (
      this.drawer.opened &&
      this.localStorage.exists(DbKeys.DrawerOpen) &&
      !this.localStorage.getDataObject<boolean>(DbKeys.DrawerOpen)
    ) {
      this.drawer.toggle();
    }

    this.subscriptions.push(
      this.fdStateService.onNewSchoolAdded
        .pipe(
          debounceTime(500),
          tap(() => this.finalizeInit(true))
        )
        .subscribe()
    );

    if (environment.configuration !== AppEnvironment.Production) {
      // don't show the roles picker in production
      this.subscriptions.push(
        this.router.events
          .pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.router.routerState.root),
            map(route => {
              while (route.firstChild) {
                route = route.firstChild;
              }

              return route;
            }),
            mergeMap(route => route.data)
          )
          .subscribe((data: RouteInfoData) => {
            this.applicableRoles = [data.roleView, ...(data.additionalRoles ?? [])].filter(x => x);
          })
      );
    }
  }

  signOut() {
    this.authService.signOut().subscribe();
    this.authService.redirectLogoutUser();
  }

  toggleDrawer() {
    this.drawer.toggle();
    this.localStorage.saveData(this.drawer.opened, DbKeys.DrawerOpen);
  }

  abortImpersonate() {
    this.authService.abortImpersonate().subscribe();
  }

  impersonateRole(reset: boolean, role?: UserRoles) {
    this.authService.impersonateRole(reset, role).subscribe();
  }

  private finalizeInit(isNewSchoolAdded: boolean = false) {
    if (isNewSchoolAdded) {
      this.$schools = this.schoolApiService.get().pipe(
        shareReplay(),
        tap(schools => {
          if (!schools.find(s => s.id === this.fdStateService.schoolId)) {
            this.fdStateService.schoolId = schools[0].id;
          }
        })
      );
    } else {
      this.authService.getLoginStatusEvent().subscribe(loginStatus => {
        if (this.authService.isLoggedIn) {
          if (!this.refreshAccountSubscription) {
            this.refreshAccountSubscription = this.accountHubService.onRefresh().subscribe();
          }
          if (loginStatus) {
            if (this.userContext.isInRole(this.UserRoles.Admin_ViewAllSchools)) {
              this.$schools = this.schoolApiService.get().pipe(
                shareReplay(),
                tap(schools => {
                  if (!schools.find(s => s.id === this.fdStateService.schoolId)) {
                    this.fdStateService.schoolId = schools[0].id;
                    this.fdStateService.schoolIdChanged();
                  }
                })
              );
            } else {
              this.schoolApiService
                .getById(this.userContext.schoolId)
                .pipe(
                  tap(item => {
                    this.schoolName = item.name;
                    this.fdStateService.schoolId = this.userContext.schoolId;
                    this.fdStateService.schoolIdChanged();
                  })
                )
                .subscribe();
            }
          }
        } else {
          this.refreshAccountSubscription?.unsubscribe();
          this.refreshAccountSubscription = null;
        }
      });
    }
  }
}
