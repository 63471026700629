/**
 *  Client version of Repository/Infrastructure/UserRoles.cs
 *  TODO: Code generation - for now, we will have to manually update both files so that they match
 */
export enum UserRoles {
  System = "System",
  System_IsTeacher = "System_IsTeacher",

  Admin = "Admin",
  Admin_Schools_View = "Admin_Schools_View",
  Admin_Schools_Edit = "Admin_Schools_Edit",
  Admin_Users_View = "Admin_Users_View",
  Admin_Users_Edit = "Admin_Users_Edit",
  Admin_SecurityGroups_View = "Admin_SecurityGroups_View",
  Admin_SecurityGroups_Edit = "Admin_SecurityGroups_Edit",
  Admin_Localization_View = "Admin_Localization_View",
  Admin_Localization_Edit = "Admin_Localization_Edit",
  Admin_Objectives_View = "Admin_Objectives_View",
  Admin_Objectives_Edit = "Admin_Objectives_Edit",
  Admin_ContentAreas_View = "Admin_ContentAreas_View",
  Admin_ContentAreas_Edit = "Admin_ContentAreas_Edit",
  Admin_ImpersonateUser = "Admin_ImpersonateUser",
  Admin_CanAssignGroups = "Admin_CanAssignGroups",
  Admin_ViewAllSchools = "Admin_ViewAllSchools",

  School = "School",
  School_SchoolYears_View = "School_SchoolYears_View",
  School_SchoolYears_Edit = "School_SchoolYears_Edit",
  School_Breaks_View = "School_Breaks_View",
  School_Breaks_Edit = "School_Breaks_Edit",
  School_Classes_View = "School_Classes_View",
  School_Classes_Edit = "School_Classes_Edit",
  School_Staff_View = "School_Staff_View",
  School_Staff_Edit = "School_Staff_Edit",
  School_SchoolObjectives_View = "School_SchoolObjectives_View",
  School_SchoolObjectives_Edit = "School_SchoolObjectives_Edit",

  Student = "Student",
  Student_Students_View = "Student_Students_View",
  Student_Students_Edit = "Student_Students_Edit",
  Student_Guardians_View = "Student_Guardians_View",
  Student_Guardians_Edit = "Student_Guardians_Edit",
  Student_StudentClasses_View = "Student_StudentClasses_View",
  Student_StudentClasses_Edit = "Student_StudentClasses_Edit",
  Student_StudentYears_View = "Student_StudentYears_View",
  Student_StudentYears_Edit = "Student_StudentYears_Edit",

  Teaching = "Teaching",
  Teaching_Grades_View = "Teaching_Grades_View",
  Teaching_Grades_Edit = "Teaching_Grades_Edit",
  Teaching_Grades_EditAllClasses = "Teaching_Grades_EditAllClasses",
  Teaching_WeeklyPlans_View = "Teaching_WeeklyPlans_View",
  Teaching_WeeklyPlans_Edit = "Teaching_WeeklyPlans_Edit",

  Reports = "Reports",
  Reports_School_View = "Reports_School_View",
  Reports_School_Edit = "Reports_School_Edit",
  Reports_Class_View = "Reports_Class_View",
  Reports_Class_Edit = "Reports_Class_Edit",
  Reports_StudentReportCard_View = "Reports_StudentReportCard_View",
  Reports_StudentReportCard_Edit = "Reports_StudentReportCard_Edit",
}
