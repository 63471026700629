import { Component, Input, OnDestroy, OnInit, booleanAttribute, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppEnvironment, FdSnackBar, LocalStoreManager } from "@fd/core";
import { finalize } from "rxjs/operators";
import { DbKeys } from "src/app/modules/shared/enums/db-keys.enum";
import { AuthService } from "src/app/modules/shared/services/auth.service";
import { AppRoutes } from "src/app/routes";
import { environment } from "src/environments/environment";
import { UserLogin } from "./user-login.model";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input({ transform: booleanAttribute }) isDeactivated = false;

  private readonly snackBar = inject(FdSnackBar);
  private readonly authService = inject(AuthService);
  private readonly localStoreManager = inject(LocalStoreManager);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  private readonly environment = environment;
  readonly AppRoutes = AppRoutes;

  userLogin = new UserLogin();
  isLoading = false;
  formResetToggle = true;
  dialogClosedCallback: () => void;
  loginStatusSubscription: any;

  showRegister = false;
  showMicrosoftLogin = false;
  showGoogleLogin = false;
  showLocalLogin = true;
  showForgotPassword = true;
  showRememberMe = true;

  get isDevelopment() {
    return this.environment.configuration === AppEnvironment.Development;
  }

  ngOnInit() {
    if (this.isDeactivated) {
      this.snackBar.openWarn("Your account is deactivated.");
      this.router.navigate([], {
        relativeTo: this.route,
        queryParamsHandling: "merge",
        queryParams: {
          isDeactivated: null, // unset the query param
        } satisfies Partial<LoginComponent>,
      });
    }

    if (!this.showRememberMe) {
      // if the user doesn't have a choice, always remember the user.
      this.authService.rememberMe = true;
    }

    this.userLogin.rememberMe = this.authService.rememberMe;

    const { wholePart } = this.authService.getReturnUrl();
    this.localStoreManager.saveSessionData(wholePart, DbKeys.ReturnUrl);

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    } else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent().subscribe(() => {
        if (this.getShouldRedirect()) {
          this.authService.redirectLoginUser();
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  getShouldRedirect() {
    return this.authService.isLoggedIn;
  }

  showErrorAlert(message: string) {
    this.snackBar.open(message);
  }

  closeDialog() {
    if (this.dialogClosedCallback) {
      this.dialogClosedCallback();
    }
  }

  signIn(backdoor: boolean = false) {
    this.isLoading = true;
    this.snackBar.open("Attempting login...");

    this.authService
      .signIn(this.userLogin.userName, this.userLogin.password, this.userLogin.rememberMe, backdoor)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(user => {
        setTimeout(() => {
          this.snackBar.dismiss();
          this.isLoading = false;
          this.reset();

          this.snackBar.open(`Welcome ${user.firstName}!`);
        }, 500);
      });
  }

  mapLoginErrorMessage(error: string) {
    if (error === "invalid_username_or_password") {
      return "Invalid username or password";
    }

    if (error === "invalid_grant") {
      return "This account has been disabled";
    }

    return error;
  }

  reset() {
    this.formResetToggle = false;

    setTimeout(() => {
      this.formResetToggle = true;
    });
  }

  rememberMeChange() {
    this.authService.rememberMe = this.userLogin.rememberMe;
  }
}
