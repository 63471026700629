<mat-toolbar color="primary" class="mat-elevation-z1">
  <div *ngIf="userContext">
    <button mat-icon-button (click)="toggleDrawer()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="clientEnvironment !== AppEnvironment.Production">
    <span class="spacer"></span>
    <app-environment-chip [clientEnvironment]="clientEnvironment" [serverEnvironment]="serverEnvironment" />
  </ng-container>

  <ng-container *ngIf="userContext">
    <span class="spacer"></span>

    <ng-container *ngIf="fdStateService.showSchools">
      <mat-form-field
        *ngIf="this.userContext.isInRole(this.UserRoles.Admin_ViewAllSchools)"
        class="school flex-grow-1 d-none d-md-inline-block mat-dense mt-4"
        appearance="outline">
        <mat-label>Schools</mat-label>
        <mat-select matInput [(value)]="fdStateService.schoolId" (selectionChange)="fdStateService.schoolIdChanged()">
          <mat-option *ngFor="let item of $schools | async" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span
        *ngIf="!this.userContext.isInRole(this.UserRoles.Admin_ViewAllSchools)"
        class="flex-grow-1 d-none d-md-inline-block mat-dense mt-2">
        {{ schoolName }}
      </span>
    </ng-container>

    <button
      *ngIf="isImpersonatingUser"
      class="d-none d-sm-block"
      mat-flat-button
      color="warn"
      matTooltip="Revert Impersonation"
      (click)="abortImpersonate()">
      Impersonating {{ fullName }}
    </button>

    <button mat-button [matMenuTriggerFor]="userMenu" class="pe-0">
      <span class="d-flex align-items-center">
        {{ fullName }}
        <mat-icon>arrow_drop_down</mat-icon>
      </span>
    </button>

    <mat-menu #userMenu xPosition="before">
      <a mat-menu-item [routerLink]="AppRoutes.account.profile.routerLink">
        <mat-icon>account_circle</mat-icon>
        <span>Profile</span>
      </a>

      <button mat-menu-item (click)="signOut()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>

      <ng-container
        *ngIf="
          environment.configuration !== AppEnvironment.Production && environment.configuration !== AppEnvironment.Beta
        ">
        <mat-divider />

        <h3 class="roles-title" (click)="$event.stopPropagation()">Impersonate Roles</h3>

        <button
          *ngFor="let role of applicableRoles"
          mat-menu-item
          (click)="impersonateRole(false, role)"
          matTooltipPosition="left">
          <mat-icon>{{ userContext.isInRole(role) ? "check_box" : "check_box_outline_blank" }}</mat-icon>
          <span>{{ role }}</span>
        </button>

        <button
          mat-menu-item
          (click)="impersonateRole(true)"
          matTooltip="This option will show up whether or not you are actively impersonating any roles.">
          <span>Reset Role Impersonation</span>
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>

  <ng-container *ngIf="showLogin">
    <span class="spacer"></span>

    <button mat-button routerLinkActive="active" [routerLink]="AppRoutes.login.routerLink">
      <mat-icon>login</mat-icon>
      Log In
    </button>
  </ng-container>
</mat-toolbar>
