import { Component, OnInit, inject } from "@angular/core";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/routes";
import { BaseComponent } from "../base.component";

@Component({
  selector: "app-redirect",
  template: "",
})
export class RedirectComponent extends BaseComponent implements OnInit {
  private readonly router = inject(Router);

  ngOnInit(): void {
    // DEVELOPER NOTE:
    // This is where you would put any custom redirect logic your app needs.
    // It may be as simple as assigning a "default" component such as this one.
    // Or it can be as complex as making a request to get additional information, checking user authorization, etc.

    // Otherwise, redirect client projects to the Home page. This can be changed to any other page as required.
    this.router.navigateByUrl(AppRoutes.home.routerLink);
  }
}
